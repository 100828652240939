import * as React from "react"
import Layout from "../../components/layout";
import Hero from "../../components/hero";

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';

import {Container, Typography} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

// markup
const FaqsPage = () => {
    return (
        <Layout title={"FAQs"}>
            <main>
                <Hero title={"Frequently Asked Questions"}
                      subtitle={"Here are the answers to some of the most frequently asked questions about Jurybox"}/>

                <Container style={{paddingTop: "3em", marginBottom: "3em"}}>
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                            <Typography variant={"h5"}>Is Jurybox an office/firm solution or for a single attorney?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>Yes. Jurybox is designed for an office to organize and collaborate all information relating to jury selection. That said, it works great for a solo attorney or for attorneys that are part of a larger organization that have yet to subscribe all of their attorneys to Jurybox.</Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                            <Typography variant={"h5"}>Is Jurybox a web application or an iPad app?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>Yes. Jurybox is both a web application and an iPad app. Your data is hosted securely in the Cloud and uses the same sign in credentials for both. So, whether you sign in with a browser or your iPad, you will see the same data. </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                            <Typography variant={"h5"}>How do I access the iPad app?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>You must first sign up for an account at <a href={"https://app.juryboxapp.com"}>app.juryboxapp.com</a>. You can then download the Jurybox app at the App Store and sign in using the same credentials you used on the website.</Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                            <Typography variant={"h5"}>Does Jurybox still work without an internet connection?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>Yes, even though Jurybox is a Cloud based app, it will still continue to work even when you temporarily loose internet access. Once you reestablish your connection, Jurybox will sync up with the Cloud.</Typography>
                            <Typography>Please note, real time collaboration is not possible without internet access, so if you want to collaborate with another user, and are concerned about a reliable internet connection, you may consider bringing a Hotspot to court. </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                            <Typography variant={"h5"}>How does Jurybox pricing work for my office?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>Jurybox is a subscription based software, which means there is a yearly fee to use it. Jurybox is offered at $399 per year per user. If your office has five attorneys/support personnel that need to use the software, your office would pay $1995 per year. Generous discounts are available for public prosecutors and public defense attorneys, as well as offices with over 5 users. Contact support@juryboxapp.com to request a quote if you fall within one of those categories.</Typography>
                        </AccordionDetails>
                    </Accordion>
                </Container>

            </main>
        </Layout>
    );
};

export default FaqsPage;
